<template>
  <div class="map-pop">
    <div class="card cardcontents">
      <div class="card-header">
        작업허가서 MAP
        <!-- <div class="card-more main-header-input">
          <c-plant
            type="search"
            name="plantCd"
            :isFirstValue="false"
            label=""
            v-model="plantCd"
            @datachange="getWorkPermit" />
        </div> -->
        <div class="card-more main-header-input map-calendar2" style="width:140px;">
          <c-datepicker
            name="workPermitDate"
            v-model="popupParam.date"
            @datachange="getWorkPermit"
          />
        </div>
        <!-- <div class="card-more main-header-input">
          <c-select
            v-if="workPermit.maps && workPermit.maps.length > 0"
            :comboItems="workPermit.maps"
            type="none"
            itemText="mapName"
            itemValue="sopMapId"
            name="sopMapId"
            label=""
            v-model="workPermit.sopMapId"
            @datachange="mapChange"
          ></c-select>
        </div> -->
      </div>
      <div class="card-body">
        <q-carousel
          v-if="workPermit.maps && workPermit.maps.length > 0"
          class="workPermit-carousel"
          swipeable
          animated
          arrows
          control-type="regular"
          control-color="orange"
          v-model="workPermit.slide"
          infinite
        >
          <q-carousel-slide 
            v-for="(map, idx) in workPermit.maps"
            :key="idx"
            :name="idx" 
            :img-src="map.mapSrc">
            <template>
              <VueDraggableResizable
                v-for="(item, idx) in map.maps"
                :key="idx"
                ref="markImage"
                class="mainMarkImage"
                :resizable="false"
                :parent="true"
                :draggable="false"
                :x="item.locationXcoordinate"
                :y="item.locationYcoordinate"
                :w="30" :h="20"
                :grid="[30, 20]"
              >
                <!-- {{idx + 1}} -->
                <q-icon name="push_pin" class="blinking" />
                <div class="markImage-abbreviation-parent"
                  :style="{ 
                    width: !item.isHover 
                      ? item.resultTypes.length > 3 ? '227px' : getWidth(item)
                      : '241px', 
                    left: getLeft(item),
                    top: getTop(item),
                  }">
                  <div class="markImage-abbreviation" 
                    @mouseover="() => { item.isHover = true }"
                    @mouseleave="() => { item.isHover = false }">
                    <transition name="mark-list" tag="div">
                      <div v-if="!item.isHover" class="markImage-tran">
                        <q-banner v-for="(resultType, idx) in item.resultTypes.slice(0, 3)"
                          :key="idx"
                          dense 
                          :class="[
                            'markImage-abbreviation-banner',
                            item.resultTypes.length < 4 && idx === item.resultTypes.length - 1 ? 'last-banner' : ''
                          ]">
                          <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                          <div>
                            {{resultType.sopWorkTypeName}}
                          </div>
                        </q-banner>
                        <q-banner v-if="item.resultTypes.length > 3"
                          key="-1"
                          dense 
                          :class="['markImage-abbreviation-plus']">
                          <q-icon name="add" class="text-red" />{{item.resultTypes.length - 3}}
                          <div>
                            <q-icon name="more_horiz" class="text-black" />
                          </div>
                        </q-banner>
                      </div>
                    </transition>
                    <transition name="mark-list" tag="div">
                      <div v-show="item.isHover">
                        <q-banner v-for="(resultType, idx) in item.resultTypes"
                          :key="idx"
                          dense 
                          :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                          <template v-slot:avatar>
                            <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                          </template>
                          {{resultType.sopWorkTypeName}}
                          <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                          <q-btn class="q-ml-sm" outline round dense color="red" size="9px" label="" icon="add" @click="openWorkPermit(resultType)" />
                          <div class="caption-work">
                            {{resultType.workSummary}}
                          </div>
                        </q-banner>
                      </div>
                    </transition>
                  </div>
                </div>
              </VueDraggableResizable>
            </template>
          </q-carousel-slide>
        </q-carousel>
        <el-empty v-else 
          :image-size="467"
          description="지도에 등록된 작업허가서가 없습니다."></el-empty>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'main-dashboard',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          companyCd: null,
          date: '',
        }
      },
    },
  },
  components: { VueDraggableResizable,
  },
  data() {
    return {
      plantCd: null,
      workPermit: {
        sopMapId: null,
        slide: 0,
        date: '',
        listUrl: '',
        mapSrc: require('@/assets/images/no-image.png'),
        maps: [],
      },
      popupOptions: {
        isFull: false,
        width: '90%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // url setting
      this.workPermit.listUrl = selectConfig.main.workPermit.url

      this.getWorkPermit();
    },
    getWorkPermit() {
      this.$http.url = this.workPermit.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: window.sessionStorage.getItem('defaultPlantCd'),
        companyCd: this.popupParam.companyCd,
        date: this.popupParam.date
      }
      this.$http.request((_result) => {
        this.workPermit.maps = _result.data;
        this.$_.forEach(this.workPermit.maps, map => {
          this.$_.forEach(map.maps, item => {
            this.$set(item, 'isHover', false)
          });
        })
      },);
    },
    mapChange(data) {
      let idx = this.$_.findIndex(this.workPermit.maps, { sopMapId: data.value });
      if (idx > -1) {
        this.$set(this.workPermit, 'slide', idx)
      } else {
        this.$set(this.workPermit, 'slide', 0)
      }
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = '작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.sopWorkTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    closePopNotice(_id) {
      if (_id == '') {
        this.noticedialog = false;
      } else {
        if(this.popRealNotices == 1) {
          document.getElementById(_id).remove();
          this.noticedialog = false;
        }
        if (this.popRealNotices > 1) {
          document.getElementById(_id).remove();
          this.popRealNotices = this.popRealNotices - 1;
        }
      }
    },
  }
};
</script>
<style lang="sass">
.map-pop
  .topmainlayer
    height: 180px
    display: block
    div.title
      padding: 30px 0px 0px 50px
      font-weight: 600
      color: #0070C0
      font-size: 20px
    .title1
      color: #17375E
      font-size: 25px
    .title2
      color: #17375E
      font-size: 35px
    .title3
      color: #0070C0
      font-size: 35px
    .mainbtns
      float: right !important
      margin-top: -120px
      padding-right: 20px
    .today
      color: #fb8c00
      padding-top: 35px
      padding-left: 23px
      font-weight: 600
  .mainchild
    // margin-top: -75px !important
    padding: 10px 0px 0px 0px
  .maincard
    margin-right: 6px
    margin-bottom: 6px
  // .maincard:hover
  //   margin-top: -10px
  .cardcontents
    margin-top: 6px
    margin-bottom: 6px
    margin-right: 6px
    .card-body
      padding: 10px !important
    .card-header:before
      font-family: "Material Icons"
      content: "\e876"
    .card-header
      height: 40px
      color: #00a1b9
      background: #f8f8f8
      display: inline-block
      padding-top: 8px
      .card-more
        float: right !important
        cursor: pointer
        color: #d2d2d2
      .card-more:hover
        color: #00a1b9
  .maincard .card-body
    background: #fff
    border-radius: 5px
    padding: 15px !important
    .fw-bold
      font-weight: 600
    .material-icons
      font-size: 3em
      color: #ddd
      cursor: pointer
  .maincard .card-body.border-color-blue
    border-left: 4px solid #0061f2
  .maincard .card-body.border-color-red
    border-left: 4px solid #d92550
  .maincard .card-body.border-color-yullow
    border-left: 4px solid #f7b924
  .maincard .card-body.border-color-green
    border-left: 4px solid #3ac47d
.map-pop
  .mainMarkImage
    padding: 0px !important
    text-align: center
    font-size: 20px
    height: 30px !important
    position: static
    padding: 16px
    color: #C10015
    // background: rgba(0,0,0,0.47)
    .markImage-abbreviation-parent
      // width:240px
      text-align: start
      position: absolute
      .markImage-abbreviation
        cursor: pointer
        display: inline-block
        // min-width:60px
        // max-width:250px
        width: auto
        border-left: 1px solid #757575
        border-right: 1px solid #757575
        border-top: 1px solid #757575
        border-bottom: 1px solid #757575
        border-radius: 5px
        .markImage-tran
          border-radius: 5px
        .markImage-abbreviation-banner
          border-right: 1px solid #757575
          display: inline-block
          text-align: center
          min-width:60px
          padding: 1px !important
          border-radius: 5px 0px 0px 5px
          i
            font-size: 20px
          div
            font-size: 12px !important
        .markImage-abbreviation-banner.last-banner
          border-right: 0
          border-radius: 0px 5px 5px 0px
        .markImage-abbreviation-plus
          display: inline-block
          text-align: center
          min-width:40px
        .markImage-abbreviation-banner-detail
          border-radius: 5px
          .q-banner__avatar i
            font-size: 20px !important
          .q-banner__content
            font-size: 12px !important
  .markImage-abbreviation > div
    -webkit-animation: fadeInFromNone 0.1s ease-out
    -moz-animation: fadeInFromNone 0.1s ease-out
    -o-animation: fadeInFromNone 0.1s ease-out
    animation: fadeInFromNone 0.1s ease-out


// .mark-list-enter-active, .mark-list-leave-active
//   transition: all 0.1s
.map-pop
  .mark-list-enter, .mark-list-leave-to
    opacity: 0
    transform: translateY(5px)

  .caption-work
    font-size: 0.85em
    font-style: italic
    white-space: normal
    color: #555
    margin-top: 4px

  .workPermit-carousel
    height: 630px !important
    .q-carousel__slide
      width: 800px !important
    .q-carousel--padding, .q-carousel__slide
      padding: 0 !important
    .q-carousel__arrow
      transition: all 0.5s
      opacity: 0.2
    .q-carousel__arrow:hover
      transition: all 0.5s
      opacity: 0.8
    .q-carousel__slides-container
      .q-panel.scroll
        overflow: hidden !important

  .main-header-input
    margin-top: -5px
    padding-right: 5px
    .customDate
      padding-bottom: 0 !important
      .q-field__inner
        padding: 0 !important
  .main-header-input2
    margin-top: -5px
    padding-right: 5px
    .customDate
      padding-bottom: 0 !important
      .q-field__inner
        padding: 0 !important
  .mainpoplayer
    padding: 6px 6px 6px 6px !important
  .anymoreNotice
    position: absolute
    bottom: 10px
    right: 20px
  .mainCircle 
    display: flex
    justify-content: center
    padding-top: 10px
    padding-left: 10px
    .circlem
      padding-top: 10px
      width: 28%
    .circleText
      text-align: center
      border-radius: 50%
      font-size: 1.2em
      font-weight: 600
      width: 120px
      height: 120px
      position: relative
      background: #fff
      p
        padding-top: 25px
    .circleText1
      border: 8px solid #82AA35
    .circleText2
      border: 8px solid #38AD85
    .circleText3
      border: 8px solid #329FAA
    .circleTextCenter
      background: #fff
      border-radius: 5px
      margin-top: -60px
      padding-top: 20px
      margin-left: 60px
      margin-right: 10px
      padding-left: 65px
      padding-right: 10px
      font-size: 1.05em
      min-height: 83px
      border: 1px solid #ddd
  .mainImprState
    height: 18px !important

  @-webkit-keyframes fadeInFromNone
    0%
      opacity: 0
    50%
      opacity: 0
    70%
      opacity: 0.5
    100%
      opacity: 1

  @-moz-keyframes fadeInFromNone
    0%
      opacity: 0
    50%
      opacity: 0
    70%
      opacity: 0.5
    100%
      opacity: 1

  @-o-keyframes fadeInFromNone
    0%
      opacity: 0
    50%
      opacity: 0
    70%
      opacity: 0.5
    100%
      opacity: 1

  @keyframes fadeInFromNone
    0%
      opacity: 0
    50%
      opacity: 0
    70%
      opacity: 0.5
    100%
      opacity: 1
  .map-calendar2 .cdatetimepic .mx-input-wrapper
    margin-top: 22px !important
    margin-bottom: 0px !important

</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}

</style>